import request from '@/utils/request'

export function getIPBlackAndWhiteList (data) {
  return request({
    url: '/user/getIPBlackAndWhiteList',
    data
  })
}
export function getAppList (data) {
  return request({
    url: '/other/getAppList',
    data
  })
}
export function saveAppOne (data) {
  return request({
    url: '/other/saveAppOne',
    data
  })
}
export function getNationBlackAndWhiteListAllIosListType (data) {
  return request({
    url: '/user/getNationBlackAndWhiteListAllIosListType',
    data
  })
}

export function updateIPBlackAndWhiteList (data) {
  return request({
    url: '/user/updateIPBlackAndWhiteList',
    data
  })
}

export function addIPBlackAndWhiteList (data) {
  return request({
    url: '/user/addIPBlackAndWhiteList',
    data
  })
}

export function getNationBlackAndWhiteList (data) {
  return request({
    url: '/user/getNationBlackAndWhiteList',
    data
  })
}

export function updateNationBlackAndWhiteList (data) {
  return request({
    url: '/user/updateNationBlackAndWhiteList',
    data
  })
}

export function getVersionBlackAndWhiteList (data) {
  return request({
    url: '/user/getVersionBlackAndWhiteList',
    data
  })
}

export function getVersionBlackAndWhiteListApi (params) {
  return request({
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'get',
    url: 'admin/audit-black-white-list-version',
    params
  })
}
export function setVersionBlackAndWhiteListApi (id, data) {
  return request({
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'PUT',
    url: `admin/audit-black-white-list-version/${id}`,
    data
  })
}

export function updateVersionBlackAndWhiteList (data) {
  return request({
    url: '/user/updateVersionBlackAndWhiteList',
    data
  })
}

export function getUserBlackAndWhiteList (data) {
  return request({
    url: '/search/getUserBlackAndWhiteList',
    data
  })
}

export function updateUserBlackAndWhiteList (data) {
  return request({
    url: '/user/updateUserBlackAndWhiteList',
    data
  })
}

export function addUserBlackAndWhiteList (data) {
  return request({
    url: '/user/addUserBlackAndWhiteList',
    data
  })
}

export function getBlackAndWhiteDeviceList (data) {
  return request({
    url: '/user/getBlackAndWhiteDeviceList',
    data
  })
}

export function getAppKeys (data) {
  return request({
    url: '/other/appKeys',
    data
  })
}

export function removeDevice (data) {
  return request({
    url: '/user/removeDevice',
    data
  })
}
