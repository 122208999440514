<template>
  <Layout>
    <component
      :is="activeName"
      :vest="VEST_MAP.FALSE"
    >
      {{ activeName }} 组件未发现
    </component>
  </Layout>
</template>

<script>
import clientVersionIOS from './components/clientVersionIOS'
const VEST_MAP = {
  TRUE: 0,
  FALSE: 1
}
export default {
  components: {
    clientVersionIOS
  },
  data () {
    return {
      VEST_MAP,
      activeName: 'clientVersionIOS'
    }
  }
}
</script>

<style>

</style>
