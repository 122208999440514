<template>
  <section>
    <BHeader style="margin: 40px 0;">
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        @submit.native.prevent
      >
        <el-form-item>
          <el-select
            v-model="formData.listType"
            placeholder="请选择状态"
            clearable
            @change="handleSearch"
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left:20px">
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
    >
      <el-table-column
        label="应用"
        prop="app_key"
        width="150px"
        :formatter="appKeyFormatter"
      />
      <el-table-column
        prop="version"
        label="版本号"
      />

      <el-table-column
        prop="list_type"
        label="状态"
        :formatter="listTypeFormatter"
      />

      <el-table-column
        prop="count_users"
        label="标记的用户数"
        width="140px"
      />

      <el-table-column
        label="APPKEY"
        prop="app_key"
        width="160px"
      />

      <el-table-column
        prop="operator"
        label="标记人"
      />

      <el-table-column
        label="标记时间(UTC)"
        prop="mark_time"
        :formatter="dataFormatter"
        width="180px"
      />

      <el-table-column
        label="支付地址"
        prop="payment_url"
        width="180px"
      >
        <template #default="{row}">
          <div class="edit-url-box">
            <el-tooltip
              v-show="row.payment_url"
              class="item"
              effect="dark"
              :content="row.payment_url"
              placement="top"
            >
              <div
                class="url"
              >
                {{ row.payment_url }}
              </div>
            </el-tooltip>
            <div
              class="edit-btn"
              @click="handleEditUrl(row)"
            >
              修改
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="400px"
      >
        <template #default="{row}">
          <el-button
            type="primary"
            :plain="row.listType === 3"
            :disabled="row.listType === 3"
            :loading="row.loading"
            @click="updateVersionBlackAndWhiteList(row, 3)"
          >
            去除标记
          </el-button>
          <el-button
            type="success"
            :plain="row.listType === 1"
            :disabled="row.listType === 1"
            :loading="row.loading"
            @click="updateVersionBlackAndWhiteList(row, 1)"
          >
            设置白名单
          </el-button>
          <el-button
            type="danger"
            :plain="row.listType === 2"
            :disabled="row.listType === 2"
            :loading="row.loading"
            @click="updateVersionBlackAndWhiteList(row, 2)"
          >
            设置黑名单
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        暂无搜索结果
      </template>
    </el-table>

    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="getVersionBlackAndWhiteList"
    />
    <el-dialog
      title="修改支付地址"
      :visible.sync="dialogVisible"
      width="600px"
      @close="handleClose"
    >
      <el-form
        ref="subFormData"
        :model="subFormData"
        @submit.native.prevent
      >
        <el-input
          v-model="subFormData.payment_url"
          placeholder="请输支付地址"
        />
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          :loading="addLoading"
          type="primary"
          @click="handleEditPayUrl"
        >保 存</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
// getVersionBlackAndWhiteList,
import {
  setVersionBlackAndWhiteListApi,
  getVersionBlackAndWhiteListApi,
  updateVersionBlackAndWhiteList,
  getAppKeys
} from '@/api/blacklist.js'

import { mapState } from 'vuex'

const typeList = [
  { name: '白名单', value: 1 },
  { name: '黑名单', value: 2 },
  { name: '未设置', value: 3 }
]
const typeMap = {
  1: '白名单',
  2: '黑名单',
  3: '未设置'
}

export default {
  props: {
    vest: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      typeList,
      typeMap,
      searchDate: [],
      formData: {
        timeStart: '',
        timeEnd: '',
        appKey: '',
        listType: '',
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      pickerOptions: {
        disabledDate (time) {
          const dateTime = time.getTime()
          return dateTime < new Date('2022-3-1').getTime()
        }
      },
      appKeysList: [],
      appKeysNameMap: {},
      dialogVisible: false, // 修改支付地址弹框
      subFormData: { },
      addLoading: false
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel', 'pkgType'])
  },
  created () {
    this.getVersionBlackAndWhiteList()
    this.getAppKeys()
  },
  methods: {
    dataFormatter (row, column, cellValue, index) {
      if (cellValue === '0000-00-00 00:00:00' || !cellValue) { return '-' }
      return cellValue
    },
    appKeyFormatter (row, column, cellValue, index) {
      return this.appKeysNameMap[cellValue] || '-'
    },
    listTypeFormatter (row, column, cellValue, index) {
      return this.typeMap[cellValue] || '-'
    },
    handleSearch () {
      this.getVersionBlackAndWhiteList()
    },
    getAppKeys () {
      getAppKeys({
        platform: 2 // 1安卓 2 IOS 用于 黑白名单设备  黑白名单国家 黑白名单客户端 筛选
      }).then(res => {
        if (res.code === 200) {
          this.appKeysList = res?.data?.list ?? []
          this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
            result[item.appKey] = item.name
            return result
          }, {})
        }
      })
    },
    // getVersionBlackAndWhiteList () {
    //   this.listInfo.loading = true
    //   const [timeStart, timeEnd] = this.searchDate || []
    //   getVersionBlackAndWhiteList({
    //     ...this.formData,
    //     channel: this.channel,
    //     pkgType: this.pkgType,
    //     timeStart,
    //     timeEnd
    //   })
    //     .then(res => {
    //       if (res.code === 200) {
    //         this.listInfo.list = res.data.list || []
    //         this.listInfo.total = res.data.total || 0
    //       }
    //     })
    //     .finally(() => {
    //       this.listInfo.loading = false
    //     })
    // },
    getVersionBlackAndWhiteList () {
      this.listInfo.loading = true
      // const [timeStart, timeEnd] = this.searchDate || []
      // ...this.formData,
      //   channel: this.channel,
      //   pkg_type: this.pkgType,
      //   timeStart,
      //   timeEnd
      getVersionBlackAndWhiteListApi({
        channel: this.channel,
        pkg_type: this.pkgType
      })
        .then(res => {
          // console.log(res, 444)
          this.listInfo.list = res.data || []
          this.listInfo.total = res.total || 0
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    updateVersionBlackAndWhiteList (row, listType) {
      const { id } = row
      this.$set(row, 'loading', true)
      updateVersionBlackAndWhiteList({
        isVest: this.vest,
        id,
        listType
      })
        .then(res => {
          if (res.code === 200) {
            this.getVersionBlackAndWhiteList()
            this.$message.success('更新成功')
          }
        })
        .finally(() => {
          row.loading = false
        })
    },
    handleEditUrl (row) {
      this.subFormData = { ...row }
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
      this.subFormData = {}
    },
    handleEditPayUrl () {
      const { id, payment_url } = this.subFormData
      if (id) {
        this.addLoading = true
        setVersionBlackAndWhiteListApi(id, {
          payment_url
        })
          .then(() => {
            this.handleClose()
            this.getVersionBlackAndWhiteList()
            this.$message.success('更新成功')
          })
          .finally(() => {
            // row.loading = false
            this.addLoading = false
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.edit-url-box {
  display:flex;
  justify-content: space-between;
  .url {
    width:60%;
    flex:1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .edit-btn{
    width:40px;
    text-align:center;
    color:rgb(38, 141, 255);
    cursor:pointer;
  }
}
</style>
